import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { Row, Col, Button } from "react-bootstrap";
import { client as prismicClient } from "../libs/prismic";
import Loader from "../components/Loader";
import { RichText } from "prismic-reactjs";
import "../styles/pages/about-us.scss";

const AboutUs = () => {
  const [aboutUsDoc, setAboutUsDoc] = useState();
  const [content, setContent] = useState();

  useEffect(()=>{
    let content = aboutUsDoc?.data;
    setContent(content);

    if(!content) {
      return
    }

    function addLineBreak(line) {
      if(line.text === '' && line.type === 'paragraph') {
        line.text = '\n';
      }
    }

    content.banner_section.forEach(addLineBreak);
    content.left_section.forEach(addLineBreak);
    content.right_section.forEach(addLineBreak);

    setContent(content);
  },[aboutUsDoc])

  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("about_us");
      setAboutUsDoc(response);
    }
    fetchData();
  }, []);

  function Mailto({ email, subject, body, ...props }) {
    return (
      <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
        {props.children}
      </a>
    );
  }
  return aboutUsDoc ? (
    <Layout>
      <Row className="pt-80 pb-top-40 mw-1440 mx-auto justify-content-center padding-mobile">
        <Col md={12}>
          <div className="rich-text">
            <RichText render={content?.banner_section} />
          </div>
        </Col>
      </Row>
      <Row className="mw-1440 mt-148 mx-auto justify-content-center mb-140 padding-mobile">
        <Col md={5}>
          <div className="rich-text about-us-richtext">
            <RichText render={content?.left_section} />
          </div>
          {/* </div> */}
        </Col>
        <Col md={2}>
          <div className="about-us-section-separator"></div>
        </Col>
        <Col md={5}>
          <div className="rich-text about-us-richtext">
            <RichText render={content?.right_section} />
          </div>
          <Button className="contact-button">
            <Mailto
              email="partners@opalvest.com"
              subject="Partner"
              body="Hello world!"
            >
              {content?.about_us_button_text[0]?.text}
            </Mailto>
          </Button>
          {/* </div> */}
        </Col>
      </Row>
    </Layout>
  ) : (
    <Loader />
  );
};

export default AboutUs;
